import React, { useState } from 'react';
import './App.css';
import logo from './components/assets/logo.png'; // Import logo

function App() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <div className="App">
      <nav className="navbar">
        <div className="navbar-brand">
          <img src={logo} alt="Logo" className="navbar-logo" />
          chessboard
        </div>
        <div className={`menu ${isNavExpanded ? "expanded" : ""}`}>
        </div>
        <div className="hamburger" onClick={toggleNav}>
        </div>
      </nav>

      <div className="main-content">
        <h1 className="main-title" style={{ fontSize: '2.0rem' }}>Second set of eyes as a service</h1>
        <p className="gradient-text">We meticulously categorize, list, and isolate every component, address, and potential outcome of your smart contracts and web3 infrastructure.</p>
        <button className="get-access">Coming soon</button>
      </div>
    </div>
  );
}

export default App;

